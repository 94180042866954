import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout style={{ height: "70vh" }}>
    <SEO title="Thank you" />
    <div className="container service-info-window mg-90">
      <h2 id="thank-you">Thank you</h2>
      <p>We will contact you as soon as we can.</p>
      <p>
        For immediate service contact us at{" "}
        <a href="tel:0413231255">0413 231 255</a> {" "}
        or email us at{" "}
        <a href="mailto:chuteoff@gmail.com">chuteoff@gmail.com</a>
      </p>
    </div>
  </Layout>
)

export default NotFoundPage
